import React from "react";
import styled from "styled-components"
import { motion } from "framer-motion"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout/Layout"
import { useUIState } from "../contexts/UIStateContext"
import ArrowNav from "../components/ArrowNav/ArrowNav"
import Seo from "../components/Seo/Seo"

const View = styled(Container)`
    padding-top: 50px;
    padding-bottom: 1.5rem;
    min-height: calc(100vh - ${props => props.offset}px);
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 768px) {
        min-height: calc(100vh - ${props => props.mobileoffset}px);
      }

    a, a:hover {
        color: #990000;
        text-decoration: none;

        &:hover {
            color: #ff9900;
        }
    }

    p, a, span{
        font-weight: regular;
        font-size: 0.8rem;
    }

    h2{
        font-weight: bold;
        font-size: 0.8rem;
        margin-bottom: 1.3rem
    }

    h4{
        font-weight: bold;
        font-size: 0.8rem;
    }
`
const Section = styled.section`
    padding: 0 12px;
`

const Impressum = ({ location }) => {
    const { totalOffset, mobileoffset } = useUIState()
    const prevPath = location.state && location.state.prevPath

    return(
        <Layout location={location} backgroundColor="bgCreme">
            <View fluid className="overflow-hidden px-0" offset={totalOffset} mobileoffset={mobileoffset}>
                <Seo title="Impressum" type="WebPage" description="Resonator Coop Architektur + Design in Aschaffenburg ist eine progressive Kooperation von Architekten und Designern für anspruchsvolle Aufgaben in den Bereichen Bauen, Planen, Gestaltung und Design." />
                <Section 
                    id="impressum"
                    as={motion.div}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.3
                    }}>
                    <Row>
                        <Col lg={{ span: 7, offset: 3 }} xl={{ span: 6, offset: 2 }}>
                            <div className="mb-5">
                                <h2>Impressum</h2>
                                <p>
                                Resonator Coop Architektur + Design<br/>
                                Hirsch + Rijkers GbR<br/>
                                Frohsinnstraße 15<br/>
                                63739 Aschaffenburg<br/>
                                    Telefon: <a href="tel:+496021449884">06021 449884</a><br />
                                E-mail: <a href="mailto:studio@resonatorcoop.de">studio@resonatorcoop.de</a><br/>
                                Ust.Id-Nr. DE 214 471 638<br/>
                                </p>
                            </div>
                            <div className="mb-5">
                                <h2>Geschäftsführer</h2>
                                <p>
                                    Lex Rijkers, Diplom-Ingenieur (TH Darmstadt) Architekt<br/>
                                    Bayerische Architektenkammer / Mitgliedsnummer 175 947<br/>
                                </p>
                                <p>
                                    Nanna Hirsch, Diplom-Designerin (FH Darmstadt)
                                </p>
                            </div>
                            <div className="mb-5">
                                <h2>Haftungs- und Urheberrechtshinweis</h2>
                                <p>Wir aktualisieren und prüfen die Informationen auf unseren Seiten ständig. Trotzdem können wir 
                                    keine Haftung oder Garantie für die Aktualität, Vollständigkeit oder Richtigkeit der Angaben übernehmen. 
                                    Resonator Coop Architektur + Design behält sich das Recht vor, Änderungen oder Ergänzungen der Informationen 
                                    jederzeit vorzunehmen. Weiterhin übernehmen wir keinerlei Haftung für Ansprüche im Zusammenhang mit diesen 
                                    Internetseiten. Gleiches gilt auch für andere Websites, auf die mittels Hyperlink verwiesen wird oder für 
                                    Sites, die auf die Internetseiten von Resonator Coop Architektur + Design durch Hyperlink verweisen. Inhalt 
                                    und Struktur sowie die auf der Website verwendeten Texte, Bilder, Grafiken, Dateien, Sounds, etc. unterliegen 
                                    dem Urheberrecht. Ihre Weitergabe, Veränderung, gewerbliche Nutzung oder Verwendung in anderen Websites oder Medien 
                                    ist nicht gestattet, bzw. Bedarf der vorherigen Zustimmung von Resonator Coop Architektur + Design.</p>
                            </div>
                            <div className="mb-5">
                                <h2>Konzept, Gestaltung + Text</h2>
                                <a href="https://www.resonatorcoop.de/">www.resonatorcoop.de</a>
                            </div>
                            <div className="mb-5">
                                <h2>Programmierung</h2>
                                <a href="https://www.maximilianmeeks.de/">www.maximilianmeeks.de</a>
                            </div>
                        </Col>
                    </Row>
                    <ArrowNav location={location} backArrow prevPath={prevPath} bgColor="bgCreme" />
                </Section>
            </View>
        </Layout>
    )
}

export default Impressum